<template>
  <v-dialog v-model="categoryDialog" persistent max-width="1000px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon
                  >{{ $vuetify.lang.t("$vuetify.CreateCategory") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="7">
                <v-sheet
                  height="300px"
                  style="border: 1px solid black; overflow-y: scroll"
                >
                  <!-- <v-list>
                    <v-list-item-group v-model="selectCategory">
                      <span
                        v-for="(item, key) in cloneCategory"
                        :key="key"
                        class="d-flex align-center justify-center"
                      >
                        <v-list-item class="ml-4 mr-2 my-2">
                          {{ item.name }}
                        </v-list-item>
                        
                        <v-btn color="red" text class="ml-2 mr-4" @click="handleDeleteCategory(item)">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </span>
                    </v-list-item-group>
                    </v-list> -->

                  <v-treeview
                    :items="cloneCategory"
                    item-children="items"
                    :active.sync="active"
                    activatable
                    hoverable
                  >
                    <template v-slot:append="{ item }">
                      <v-icon @click="deleteCategory(item)" color="red" right>
                        mdi-trash-can-outline
                      </v-icon>
                    </template>
                  </v-treeview>
                </v-sheet>
              </v-col>

              <v-col cols="5">
                <v-select
                  :label="this.$vuetify.lang.t('$vuetify.parentCategory')"
                  :items="category"
                  :item-text="(item) => item.name"
                  v-model="categoryObj.parent"
                  outlined
                  clearable
                />

                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.categoryName')"
                  v-model="categoryObj.name"
                  outlined
                />
                <v-textarea
                  height="130px"
                  :label="$vuetify.lang.t('$vuetify.note')"
                  v-model="categoryObj.note"
                  outlined
                />

                <span class="d-flex justify-end">
                  <v-btn
                    color="green"
                    class="ma-0"
                    outlined
                    @click="handleAddCategory"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    {{ $vuetify.lang.t("$vuetify.save") }}
                  </v-btn>
                </span>

                <span class="d-flex justify-end mt-3">
                  <span class="headline red--text">
                    Please click save to confirm operation
                  </span>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0 pb-6 pl-5">
        <v-btn color="green" @click="handleCategoryDone">
          {{ $vuetify.lang.t("$vuetify.done") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  functionTypesList,
  functionTypesUpsert,
  functionTypesDel,
} from "@/api/system";

function changeNullItems(cloneCategory) {
  if (cloneCategory.items == null) {
    cloneCategory.items = [];
  } else {
    for (let i = 0; i < cloneCategory.items.length; i++) {
      changeNullItems(cloneCategory.items[i]);
    }
  }
}

function findSelected(list, id) {
  let dept = undefined;
  for (let i = 0; i < list.length; i++) {
    if (list[i].id == id) {
      return list[i];
    }
    dept = findSelected(list[i].items, id);
    if (dept != undefined) {
      break;
    }
  }
  return dept;
}

const newObj = () => {
  return {
    id: "",
    parent: "",
    code: "",
    name: "",
    note: "",
    order: 0,
    items: [],
  };
};

export default {
  name: "FuncCategory",
  props: {
    myObj: Object,
    categoryDialog: Boolean,
    category: Array,
  },
  components: {
    DialogDelete: () => import("@/components/deleteDialog"),
  },
  data() {
    return {
      valid: false,
      selectCategory: "",
      cloneCategory: [],
      active: [],
      categoryObj: newObj(),
    };
  },
  methods: {
    handleCategoryDone() {
      this.$emit("update:categoryDialog", false);
      this.$emit("handleDone");
    },
    handleDeleteCategory(data) {
      functionTypesDel(data.id)
        .then(() => {
          this.getData();
          this.$emit("handleDone");
        })
        .catch((err) => console.log("Delete Category Err", err));
    },
    getData() {
      this.categoryObj = newObj();

      functionTypesList()
        .then((res) => {
          this.cloneCategory = [...res.items];
          this.cloneCategory.forEach((c) => changeNullItems(c));
        })
        .catch((err) => {
          console.log("TypeList Error", err);
        });
    },
    handleAddCategory() {
      if (this.$refs.form.validate() == true) {
        functionTypesUpsert(this.categoryObj)
          .then(() => {
            this.getData();
            this.active = [];
            this.categoryObj = newObj();
          })
          .catch((err) => console.log("Add Category Err", err));
      } else {
        console.log("false");
      }
    },
    async deleteAllChildrenCategory(delDept) {
      if (delDept.items.length) {
        for (let i = 0; i < delDept.items.length; i++) {
          await this.deleteAllChildrenCategory(delDept.items[i]);
        }
      }
      await functionTypesDel(delDept.id).catch((err) => {
        console.log("Delete Category Error", err);
        this.$toast.error("Delete Cateogyr Error");
      });
    },
    async deleteCategory(item) {
      await this.deleteAllChildrenCategory(item);
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    active: function () {
      if (this.active.length > 0) {
        this.categoryObj = newObj();

        const temp = findSelected(this.cloneCategory, this.active[0]);

        this.categoryObj.id = temp.id;
        this.categoryObj.parent = temp.parent;
        this.categoryObj.code = temp.code;
        this.categoryObj.name = temp.name;
        this.categoryObj.note = temp.note;
        this.categoryObj.order = temp.order;
        this.categoryObj.items = temp.items;
      } else {
        this.categoryObj = newObj();
      }
    },
  },
};
</script>